<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input uppercase"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Invoice/Adjustment No."
                v-model="innerValue.invoiceNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Customer Name"
                v-model="innerValue.cusName"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Quote No."
                v-model="innerValue.quoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div v-if="invoiceTypes"
            class="field">
            <div class="control">
              <div class="select is-fullwidth"
                :class="[`select-${$user.info.theme}`]">
                <select v-model="innerValue.invoiceType">
                  <option value="0">-- Invoice Type --</option>
                  <option v-for="(invoiceValue, invoiceKey, index) in invoiceTypes"
                    :value="invoiceValue"
                    :key="index">
                    {{ invoiceKey }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import _cloneDeep from 'lodash/cloneDeep'
import { InvoiceTypes } from '@/enums'

export default {
  name: 'DebtorAdjustmentListSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    invoiceTypes() {
      let types = Object.fromEntries(Object.entries(InvoiceTypes).filter((v) => v[1] !== InvoiceTypes.Advance))
      return types
    }
  },
  watch: {
    selectedFromDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.fromDate = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.fromDate = ''
      }
    },
    selectedToDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.toDate = this.selectedToDate.toISOString()
      } else {
        this.innerValue.toDate = ''
      }
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {
    if (this.innerValue.fromDate) {
      this.selectedFromDate = new Date(this.innerValue.fromDate)
    }
    if (this.innerValue.toDate) {
      this.selectedToDate = new Date(this.innerValue.toDate)
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
